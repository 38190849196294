@import '../App.scss';

header {
  background-image: url('../assets/images/headerBackground.jpg');
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
