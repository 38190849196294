@import '../App.scss';

.Nav {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  right: 0;
  left: 0;
  z-index: 3;
  text-align: start;

  button {
    color: white;

    &:hover {
      color: gray;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .Nav {
    .button-toggle {
      display: none;
    }

    .links {
      display: flex;
    }
  }
}
