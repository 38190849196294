@import '../App.scss';

.Projects {
  display: flex;
  flex-wrap: wrap;

  .project-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 1px 0 5px #f5f5f5, 0 1px 5px #f5f5f5, -1px 0 5px #f5f5f5,
      0 -1px 5px #f5f5f5;

    &:hover {
      background: #f5f5f5;
      box-shadow: none;
    }
  }
}

@media only screen and (min-width: 600px) {
  .Projects {
    .project-card {
      width: 45%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .Projects {
    .project-card {
      width: 30%;
    }
  }
}
