.App {
  width: 100vw;

  a,
  input,
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--color-primary);
    outline: none;
    resize: none;
    border: none;
    background: inherit;
    padding: 0;
    text-decoration: none;
  }

  button {
    background: transparent;
    &:focus {
      outline: 0;
    }
    border: none;
    border-radius: 1mm;
  }

  .Projects,
  .Skills,
  .Experience,
  .Education {
    border-bottom: 1px solid gainsboro;

    h2 {
      margin-bottom: 30px;
      background: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p,
    ul {
      color: #1c1c1e;
    }

    .text-time {
      color: #5d707f;
    }
  }

  .contact {
    position: relative;
    height: 300px;
    background-image: url('./assets/images/footerBackground.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .text-background {
      background-color: rgba(33, 37, 41, 0.8);
    }
  }

  .text-background {
    background-color: rgba(33, 37, 41, 0.6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  .text {
    z-index: 2;
    color: white;
    i {
      &:hover {
        color: black;
      }
      font-size: 2rem;
    }
  }
}
